<template>
  <div class="navigation-layout" :class="{ light: dataSources.current.mode === 'light' }">
    <div class="head d-flex align-center">
      <v-img :src="url" max-height="40" max-width="90" />
      <v-spacer></v-spacer>
      <wap-call-phone-icon
      ></wap-call-phone-icon>
    </div>

    <div class="main">
      <div class="title" v-if="assists.tool.isNotEmpty(dataSources.current.title)">
        {{ dataSources.current.title }}
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'
import WapCallPhoneIcon from '@/components/base/WapCallPhoneIcon'
import { OnlineApi } from '@/api'

export default {
  name: 'CrocNavigationLayout',
  components: {
    WapCallPhoneIcon
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        original: {
          logoUrl: '',
          reverseLogoUrl: ''
        },
        current: {
          title: '',
          mode: 'dark'
        }
      }
    }
  },
  watch: {
    $route () {
      this.dataSources.current.title = this.$route.meta.title
      this.dataSources.current.mode = this.$route.meta.mode
      if (this.$route.path.indexOf('overview') !== -1) {
        this.getPersonalInfo()
      }
    }
  },
  computed: {
    url () {
      const { logoUrl, reverseLogoUrl } = this.$store.getters.getPortfolioJson
      return this.dataSources.current.mode === 'light' ? reverseLogoUrl : logoUrl
    }
  },
  mounted () {
    this.dataSources.current.title = this.$route.meta.title
    this.dataSources.current.mode = this.$route.meta.mode
    if (this.$route.path.indexOf('overview') !== -1) {
      this.getPersonalInfo()
    }
  },
  methods: {
    getPersonalInfo () {
      OnlineApi.getPaymentPersonalInfo({ loanNo: this.$store.getters.getCollectionLoanNo },
        (res) => {
          this.dataSources.current.title = `Hi, ${res.firstName}`
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.navigation-layout
  & .head
    position: fixed
    top: 0
    width: 100%
    padding: 6px 24px
    background-color: #fff
    z-index: 9
  & .main
    padding-bottom:  74px
    overflow: auto
    & .title
      margin-top: 52px
      padding: 24px
      font-size: 24px !important
      font-weight: bold

.light
  & .head,.title
    color: #fff !important
    background-color: var(--theme_secondary)
</style>

<style lang="sass">
.navigation-layout
  & .footer
    position: fixed
    left: 0
    bottom: 0
    display: flex
    align-items: center
    width: 100%
    padding: 12px 24px
    background: white
    z-index: 9
</style>
