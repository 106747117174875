<template>
  <v-btn
    icon
    small
    @click="callPhone"
  >
    <v-icon :color="color" size="18">support_agent</v-icon>
  </v-btn>
</template>

<script>
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'wap-call-phone-icon',
  props: {
    color: {
      type: String
    }
  },
  data: () => ({
    layouts: {
      data: {
        mode: '',
        portfolio: {
          hotlinePhone: ''
        }
      }
    }
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    if (tool.isNotEmpty(this.$store.getters.getPortfolioJson)) {
      this.layouts.data.portfolio = this.$store.getters.getPortfolioJson
    }
  },
  methods: {
    callPhone () {
      window.location.href = 'tel://' + this.layouts.data.portfolio.hotlinePhone
    }
  }
}
</script>

<style scoped>

</style>
